import React, {useState} from 'react'
import * as Styled from '../styles/musique.style.jsx'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import _ from 'lodash'
import ReactPlayer from 'react-player'

import Footer from '../components/Footer'
import Release from '../components/Release'

const slugify = require('slugify')

const Musique = ({data}) => {
  let singlesEpsToAdd = data.prismicListeMusique.data.ep_et_singles_a_afficher
  let releases = data.allReleases.nodes.filter((release) => {
    if (
      release.name === 'Endless Cultural Turnover' &&
      release.tracks.length === 1
    )
      return null
    return singlesEpsToAdd
      ?.map(
        (singleEpToAdd) =>
          singleEpToAdd.single_ep_title.toUpperCase() ===
          release.name.toUpperCase()
      )
      .includes(true)
  })
  let releasesOrdered = _.orderBy(releases, ['date'], ['desc'])
  let releasesOrderLinked = releasesOrdered.map((release) => {
    const releasePrismic = singlesEpsToAdd.find(
      (singleEpToAdd) =>
        singleEpToAdd.single_ep_title.toUpperCase() ===
        release.name.toUpperCase()
    )
    return {
      ...release,
      shop_link:
        releasePrismic.shop_product_name &&
        `/product/${slugify(releasePrismic.shop_product_name, {
          replacement: '-',
          remove: null,
          lower: true,
          strict: true,
          locale: 'fr'
        })}`,
      linkfire: releasePrismic.linkfire,
      link_textes:
        releasePrismic.page_textes &&
        `/textes?page=${releasePrismic.page_textes}`
    }
  })

  const [trackPlaying, setTrackPlaying] = useState(null)
  const [playing, setPlaying] = useState(false)

  return (
    <>
      <Styled.Musique>
        <ReactPlayer
          url={trackPlaying}
          playing={playing}
          style={{display: 'none'}}
          playsinline
        />
        <Helmet title="Musique | Jacques" htmlAttributes={{lang: 'fr'}} />
        {releasesOrderLinked.map((release, i) => (
          <Release
            release={release}
            trackPlaying={trackPlaying}
            setTrackPlaying={setTrackPlaying}
            playing={playing}
            setPlaying={setPlaying}
            key={i}
          />
        ))}
      </Styled.Musique>
      <Footer />
    </>
  )
}

export const query = graphql`
  {
    prismicListeMusique {
      data {
        ep_et_singles_a_afficher {
          single_ep_title
          shop_product_name
          page_textes
          linkfire
        }
      }
    }
    allReleases {
      nodes {
        name
        artists
        cover
        date(formatString: "YYYY")
        link
        album_group
        tracks {
          name
          link
          duration
          preview
          track_number
        }
      }
    }
  }
`

export default Musique
