import styled from 'styled-components'
import theme from '../../styles/theme'

export const ReleaseTrack = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 1.5rem;
  border-bottom: 1px solid ${theme.colors.light};
  padding: 0.5rem 0 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding-right: ${theme.paddingAround};
  gap: 1rem;
  &:hover {
    background: ${theme.colors.light};
    color: ${theme.colors.dark};
  }
  &:hover .playIcon {
    filter: invert(100%);
  }
  .leftPart,
  .rightPart {
    display: flex;
    justify-content: space-between;
    > * {
      display: flex;
      align-items: center;
    }
  }
  .trackNumber {
    min-width: 3rem;
    @media (max-width: ${theme.breakpoints.md}px) {
      min-width: 2rem;
    }
    img {
      height: 1.5rem;
      transition: all 0.3s ease;
    }
  }
  ${
    '' /* .trackName {
    display: flex;
    align-items: center;
  }
  .duration {
  } */
  }
`
