import React from 'react'
import * as Styled from './ReleaseTrack.style'

import play from '../../images/play.svg'

export default function ReleaseTrack({
  track,
  trackPlaying,
  setTrackPlaying,
  playing,
  setPlaying
}) {
  function toHHMMSS(miliseconds) {
    let sec_num = parseInt(miliseconds / 1000, 10)
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor((sec_num - hours * 3600) / 60)
    let seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  }

  return (
    <Styled.ReleaseTrack
      onClick={() => {
        setTrackPlaying(track.preview)
        if (!playing) setPlaying(true)
        else if (playing && trackPlaying === track.preview) setPlaying(!playing)
      }}
      role="presentation"
    >
      <div className="leftPart">
        <div className="trackNumber">
          {playing && trackPlaying === track.preview ? (
            <img className="playIcon" src={play} alt="play icon" />
          ) : (
            track.track_number + '.'
          )}
        </div>
        <div className="trackName">{track.name}</div>
      </div>
      <div className="rightPart">
        <div className="duration">{toHHMMSS(track.duration)}</div>
      </div>
    </Styled.ReleaseTrack>
  )
}
