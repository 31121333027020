import React from 'react'
import * as Styled from './Release.style'
import {Link} from 'gatsby'

import ReleaseTrack from '../ReleaseTrack'
import {StaticImage} from 'gatsby-plugin-image'

export default function Release({
  release,
  trackPlaying,
  setTrackPlaying,
  playing,
  setPlaying
}) {
  return (
    <Styled.Release>
      <div className="ReleaseInfos">
        <div className="content">
          <div className="ReleaseInfos__infos">
            <h2 className="name">{release.name}</h2>

            <h4 className="artist">
              Jacques
              {release.artists.length > 1 &&
                release.artists.map((artist) => {
                  if (artist !== 'Jacques') return ' feat. ' + artist
                  return null
                })}
            </h4>
            <h3 className="date">{release.date}</h3>
          </div>
          <div className="ReleaseInfos__cover">
            <img src={release.cover} alt={release.name} />
          </div>
          <div className="ReleaseInfos__buttons">
            {release.linkfire && (
              <a
                href={release.linkfire}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>
                  <span>STREAM</span>
                </button>
              </a>
            )}
            {release.shop_link && (
              <Link to={release.shop_link}>
                <button>
                  <span>SHOP</span>
                </button>
              </Link>
            )}
          </div>
          {/* {release.link_textes && (
          <div className="ReleaseInfos__buttons">
            <Link to={release.link_textes}>
              <StaticImage
                src="../../images/jacques_face.png"
                alt="textes"
                className="textes"
              />
            </Link>
          </div>
        )} */}
        </div>
      </div>
      <div className="releaseTracks">
        {release.tracks.map((track, i) => (
          <ReleaseTrack
            track={track}
            trackPlaying={trackPlaying}
            setTrackPlaying={setTrackPlaying}
            playing={playing}
            setPlaying={setPlaying}
            key={i}
          />
        ))}
      </div>
    </Styled.Release>
  )
}
