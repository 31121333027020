import theme from './theme'
import styled from 'styled-components'

export const Musique = styled.main`
  display: flex;
  flex-direction: column;
  @media (max-width: ${theme.breakpoints.md}px) {
    gap: 2rem;
  }
`
