import styled from 'styled-components'
import theme from '../../styles/theme'

export const Release = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.colors.light};
  @media (max-width: ${theme.breakpoints.md}px) {
    flex-direction: column;
  }
  .ReleaseInfos {
    width: 300px;
    border-right: 1px solid ${theme.colors.light};
    padding-left: ${theme.paddingAround};
    padding-right: 1rem;
    @media (max-width: ${theme.breakpoints.md}px) {
      width: calc(100% - ${theme.paddingAround} * 2);
      border-right: none;
      border-bottom: 1px solid ${theme.colors.light};
      padding: 0 ${theme.paddingAround};
    }
    position: relative;
    .content {
      position: sticky;
      top: 70px;
      padding-bottom: 10px;
    }
    .ReleaseInfos__infos {
      .artist {
        margin: 0.5rem 0 0.5rem 0;
      }
      .name {
        margin: 1rem 0 0 0;
        text-transform: uppercase;
      }
      .date {
        font-size: 0.8rem;
        color: grey;
        margin-top: 0;
      }
    }
    .ReleaseInfos__cover {
      ${'' /* object-fit: cover; */}
      img {
        width: 100%;
      }
    }
    .ReleaseInfos__buttons {
      display: flex;
      gap: 2rem;
      justify-content: center;
      margin: 2rem 0;
      button {
        border: 1px solid ${theme.colors.light};
        padding: 0.5rem 1rem;
        transition: all 0.3s ease;
        &:hover {
          background: ${theme.colors.light};
          color: ${theme.colors.dark};
        }
      }
    }
  }
  .releaseTracks {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }
`
